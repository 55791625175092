<template>
   <div class="card mb-1">
		<div class="card-body p-12">
         <!-- Cabecalho -->
         <div class="row">
            <div class="col mb-2 align-self-center">
               <div class="limitador-1 mb-1">
                  <i class="far fa-store color-theme font-13 me-1"></i><strong class="subtitle font-15 text-capitalize"> {{ loja.nomeLoja }}</strong>
                  <small class="mx-2">|</small><span class="font-13"><i class="far fa-tag font-11 me-1"></i> {{ loja.numeroLoja }}</span>
               </div>
            </div>
            <div class="w-max-content mb-2">
               <a href="javascript:;" class="badge badge-default-outline mb-1 cursor-auto d-none d-xl-block">
                  <i class="far fa-info-circle me-1"></i> Sangrias/Pagamentos contabilizados na loja de {{ dataInicio.getDate().toString().padStart(2, "0") +'/'+ (dataInicio.getMonth() + 1).toString().padStart(2, "0") +'/'+ dataInicio.getFullYear() }} até {{ dataFim.getDate().toString().padStart(2, "0") +'/'+ (dataFim.getMonth() + 1).toString().padStart(2, "0") +'/'+ dataFim.getFullYear() }}
					</a>
            </div>
            <div class="w-max-content mb-2 d-none d-lg-block">
               <a href="javascript:;" class="badge badge-default mb-1" @click="outrasFormasPgto = true;" v-if="outrasFormasPgto == false"><i class="fas fa-plus me-1"></i> Ver mais</a>
               <a href="javascript:;" class="badge badge-default mb-1" @click="outrasFormasPgto = false;" v-else><i class="far fa-home me-1"></i> Resumo</a>
            </div>
            <div class="w-max-content mb-2">
               <a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
            </div>
         </div>

         <!-- Principal -->
			<div class="row">
            <!-- Cabecalho -->
            <div class="col-12 px-0 d-none d-lg-block" v-if="loja.pdvs.length > 0">
               <div class="row row-cols-2 row-cols-sm-3 row-cols-md-4 text-center font-13" :class="outrasFormasPgto ? 'row-cols-lg-6 row-cols-xl-7' : 'row-cols-lg-5 row-cols-xl-6'">
                  <div class="pdv mb-2 d-none d-lg-block">
                     <div class="card mb-0 h-100">
                        <div class="card-body py-2 px-0"><strong class="me-1 weight-500">Caixa</strong></div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? 'd-none' : 'd-lg-none d-xl-block'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0"><i class="far fa-box me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Qtd. Sangrias</strong></div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? 'd-none' : ''">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0"><i class="far fa-envelope-open me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Sangrias</strong></div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? 'd-none' : ''">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0"><i class="far fa-money-bill-alt me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Em caixa</strong></div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? 'd-none' : ''">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0"><i class="far fa-dollar-sign me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Dinheiro</strong></div>
                     </div>
                  </div>
                  <div class="col d-none" :class="outrasFormasPgto ? 'd-xl-block' : 'd-none'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0"><i class="far fa-address-card me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Convênio</strong></div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? '' : 'd-none'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0"><i class="far fa-piggy-bank me-2 font-12 color-theme"></i><strong class="me-1 weight-500">PIX</strong></div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? '' : 'd-none'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0"><i class="far fa-piggy-bank me-2 font-12 color-theme"></i><strong class="me-1 weight-500">PIX (POS)</strong></div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? '' : 'd-none'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0"><i class="far fa-credit-card-front me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Débito</strong></div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? '' : 'd-none'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0"><i class="far fa-credit-card-front me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Débito (POS)</strong></div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? '' : 'd-none'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0"><i class="far fa-credit-card me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Crédito</strong></div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? '' : 'd-none'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0"><i class="far fa-credit-card me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Crédito (POS)</strong></div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? 'd-none' : ''">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0"><i class="far fa-ellipsis-h me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Outros</strong></div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? 'd-none' : ''">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0 weight-500"><i class="far fa-equals me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Total</strong></div>
                     </div>
                  </div>
               </div>
				</div>

            <pdv class="d-none d-lg-block" v-for="(pdv, index) in loja.pdvs" :key="index" :index="index" :pdv="pdv" :outrasFormasPgto="outrasFormasPgto" 
               @buscarComprovantes="buscarComprovantes($event)" />
               
            <div class="col-12 my-5 text-center" v-if="loja.pdvs.length == 0">
               <i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
               <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum caixa encontrado</h4>
            </div>

            <!-- Total -->
            <div class="col-12 px-0 mt-1" v-if="loja.pdvs.length > 0">
               <div class="row row-cols-2 row-cols-sm-3 row-cols-md-4 text-center font-13 weight-500" :class="outrasFormasPgto ? 'row-cols-lg-6 row-cols-xl-7' : 'row-cols-lg-5 row-cols-xl-6'">
                  <div class="pdv mb-2 d-none d-lg-block">
                     <div class="card mb-0 h-100">
                        <div class="card-body py-2 px-0"><strong class="me-1 weight-500">Total</strong></div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? 'd-lg-none' : 'd-lg-none d-xl-block'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0">
                           <span class="d-block d-lg-none"><i class="far fa-box me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Qtd. Sangrias</strong></span>
                           {{ parseInt(loja.qtdSangria) }}
                        </div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? 'd-lg-none' : ''">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0">
                           <span class="d-block d-lg-none"><i class="far fa-envelope-open me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Sangrias</strong></span>
                           <small class="font-10">R$</small> {{ parseFloat(loja.totalSangria).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                        </div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? 'd-none' : 'd-none d-lg-block'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0">
                           <span class="d-block d-lg-none"><i class="far fa-money-bill-alt me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Em caixa</strong></span>
                           <small class="font-10">R$</small> {{ parseFloat(loja.totalCaixa).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                        </div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? 'd-lg-none' : ''">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0">
                           <span class="d-block d-lg-none"><i class="far fa-dollar-sign me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Dinheiro</strong></span>
                           <small class="font-10">R$</small> {{ parseFloat(loja.totalDinheiro).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                        </div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? 'd-lg-none d-xl-block' : 'd-lg-none'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0">
                           <span class="d-block d-lg-none"><i class="far fa-address-card me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Convênio</strong></span>
                           <small class="font-10">R$</small> {{ parseFloat(loja.totalConvenio).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                        </div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? '' : 'd-lg-none'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0">
                           <span class="d-block d-lg-none"><i class="far fa-piggy-bank me-2 font-12 color-theme"></i><strong class="me-1 weight-500">PIX</strong></span>
                           <small class="font-10">R$</small> {{ parseFloat(loja.totalPix).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                        </div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? '' : 'd-lg-none'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0">
                           <span class="d-block d-lg-none"><i class="far fa-piggy-bank me-2 font-12 color-theme"></i><strong class="me-1 weight-500">PIX (POS)</strong></span>
                           <small class="font-10">R$</small> {{ parseFloat(loja.totalPixPos).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                        </div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? '' : 'd-lg-none'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0">
                           <span class="d-block d-lg-none"><i class="far fa-credit-card-front me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Débito</strong></span>
                           <small class="font-10">R$</small> {{ parseFloat(loja.totalDebito).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                        </div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? '' : 'd-lg-none'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0">
                           <span class="d-block d-lg-none"><i class="far fa-credit-card-front me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Débito (POS)</strong></span>
                           <small class="font-10">R$</small> {{ parseFloat(loja.totalDebitoPos).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                        </div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? '' : 'd-lg-none'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0">
                           <span class="d-block d-lg-none"><i class="far fa-credit-card me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Crédito</strong></span>
                           <small class="font-10">R$</small> {{ parseFloat(loja.totalCredito).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                        </div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? '' : 'd-lg-none'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0">
                           <span class="d-block d-lg-none"><i class="far fa-credit-card me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Crédito (POS)</strong></span>
                           <small class="font-10">R$</small> {{ parseFloat(loja.totalCreditoPos).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                        </div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? 'd-none' : 'd-none d-lg-block'">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0">
                           <span class="d-block d-lg-none"><i class="far fa-ellipsis-h me-2 font-12 color-theme"></i><strong class="me-1 weight-500">Outros</strong></span>
                           <small class="font-10">R$</small> {{ parseFloat(loja.totalOutros).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                        </div>
                     </div>
                  </div>
                  <div class="col" :class="outrasFormasPgto ? 'd-lg-none' : ''">
                     <div class="card mb-2">
                        <div class="card-body py-2 px-0 color-theme">
                           <span class="d-block d-lg-none"><i class="far fa-equals me-2 font-12"></i><strong class="me-1 weight-500">Total</strong></span>
                           <small class="font-10">R$</small> {{ parseFloat(loja.vendaTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                        </div>
                     </div>
                  </div>
               </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import pdv from '@/components/resultados/vendas/Pdv.vue'

export default {
	name: 'Loja',
	props: ['loja', 'index'],
   data: function () {
      return {
         outrasFormasPgto: false,
			dataInicio: new Date(this.loja.dataInicio),
			dataFim: new Date(this.loja.dataFim)
      }
   },
   components: {
      pdv
   },
   methods: {
      exportar : function () {
         this.$store.dispatch('exportarExcel', {
				'nome': ('Vendas - '+ this.loja.nomeLoja),
				'resultado': Array.of(this.loja), 
				'campos': ['numeroLoja', 'nomeLoja', 'dataInicio', 'dataFim', 'qtdSangria', 'totalSangria', 'totalDinheiro', 'totalConvenio', 'totalPix', 'totalPixPos', 'totalDebito', 'totalDebitoPos', 'totalCredito', 'totalCreditoPos', 'vendaTotal'], 
				'lista': {'nome': 'pdvs', 'campos': ['pdv', 'qtdSangria', 'valorSangria', 'valorDinheiro', 'valorConvenio', 'valorPix', 'valorPixPos', 'valorDebito', 'valorDebitoPos', 'valorCredito', 'valorCreditoPos', 'valorTotal']}
			})
      },
      buscarComprovantes : function (objPdv) {
         this.$emit('buscarComprovantes', {
            'pdv': objPdv.pdv,
            'numeroPdv': objPdv.numeroPdv,
            'idLoja': this.loja.idLoja,
            'numeroLoja': this.loja.numeroLoja,
            'dataInicio': this.loja.dataInicio,
            'dataFim': this.loja.dataFim,
            'formaPagamento': objPdv.formaPagamento
         })
      }
   }
}

</script>

<style scoped>

.pdv {
   width: 90px;
}

@media (min-width: 992px) {
   .row-cols-lg-5>*:not(.pdv) {
      flex: 0 0 auto;
      width: calc(20% - 18px);
   }

   .row-cols-lg-6>*:not(.pdv) {
      flex: 0 0 auto;
      width: calc(16.666666666666668% - 15px);
   }
}

@media (min-width: 1200px) {
   .row-cols-xl-6>*:not(.pdv) {
      flex: 0 0 auto;
      width: calc(16.666666666666668% - 15px);
   }

   .row-cols-xl-7>*:not(.pdv) {
      flex: 0 0 auto;
      width: calc(14.28% - 12.85px);
   }
}

</style>